
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { DataList, PersonList } from '@/types/scheduling'
import { TimeList, ProjectList } from '@/types/onlineStatistics'

@Component

export default class ScheduleAdd extends Vue {
  private disabled = true
  private workerList: Array<{ workerId: string; workerName: string; checked: boolean }> = []
  private workTimeList: TimeList[] = []
  private workTime = ''
  private info: DataList = {
    projectId: '',
    // 排班日期
    schedulingDate: [],
    // 排班人数
    schedulingSum: '',
    // 排班总人数
    workerAmount: '',
    // 绑定设备数
    deviceAmount: ''
  }

  private tableData: PersonList[] = []
  private operation = false

  private rules = {
    projectId: [
      { required: true, message: '请选择所属项目', trigger: ['blur', 'change'] }
    ],
    schedulingDate: [
      { required: true, message: '请选择排班日期', trigger: ['blur', 'change'] },
      { validator: this.validateMaxNum, trigger: ['blur', 'change'] }
    ],
    schedulingSum: [
      { required: true, message: '请输入排班人数', trigger: ['blur', 'change'] }
    ]
  }

  private pickerOptions = {}
  private projectList: Array<ProjectList> = []

  // 在岗时间段
  get workTimeInfo () {
    let data = this.workTimeList.length > 0 ? '' : '--'
    this.workTimeList.forEach((item, index) => {
      data += '时间段' + (index + 1) + ':&nbsp;' + item.startTime + '~' + item.endTime + '&nbsp;&nbsp;&nbsp;'
    })
    return data
  }

  get recordId () {
    return this.$route.params.recordId || ''
  }

  created () {
    this.getWorkerProjectList()
    this.recordId && this.getDetail()
  }

  validateMaxNum (rule: any, value: Array<string>, callback: Function) {
    if (value && value.length > 30) {
      callback(new Error('添加日期不超过30天'))
    } else {
      return callback()
    }
  }

  // 项目工人聚合列表
  getWorkerProjectList () {
    this.$axios.get(this.$apis.recruitUse.selectYhWorkerProjectByPage).then(res => {
      res.list.forEach((item: ProjectList) => {
        // 过滤已移交
        if (item.projectState !== '3') {
          this.projectList.push(item)
        }
      })
    })
  }

  // 选择项目后获取信息
  selectProject (value: string) {
    const item = this.projectList.find((item: any) => item.projectId === value) as any
    this.info.workerAmount = item.workerAmount || '0'
    this.info.deviceAmount = item.deviceAmount || '0'
    // 禁用排班日期
    if (this.info.workerAmount !== '0') {
      this.disabled = false
    } else {
      this.disabled = true
    }
    this.info.schedulingDate = []
    this.info.schedulingSum = ''
    this.operation = false
    this.tableData = []
    this.getWorkerList()
    this.getWorkTimeListByProjectId()
    this.offDate(value)
  }

  // 已排班日期
  offDate (value: string) {
    this.$axios.get(this.$apis.recruitUse.selectYhWorkerRecordByPage, {
      projectId: value
    }).then(res => {
      const dateList: string[] = []
      if (res.list && res.list.length > 0) {
        res.list.forEach((item: { schedulingDate: string }) => {
          dateList.push(item.schedulingDate)
        })
      }
      this.pickerOptions = {
        disabledDate: (time: Date) => {
          // 大于当前日期禁止选择
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000 || dateList.indexOf(this.$dayjs(time).format('YYYY-MM-DD')) !== -1
        }
      }
    })
  }

  // 查询详情
  getDetail () {
    this.$axios.get(this.$apis.recruitUse.selectYhWorkerRecordByRecordId, {
      recordId: this.recordId
    }).then(res => {
      this.info = {
        recordId: res.recordId,
        projectId: res.projectId,
        schedulingDate: [res.schedulingDate],
        schedulingSum: res.schedulingSum,
        workerAmount: res.workerAmount,
        deviceAmount: res.deviceAmount
      }
      this.tableData = res.schedulingList || []
      this.getWorkerList()
      this.getWorkTimeListByProjectId()
    })
  }

  // 查询项目工人列表
  getWorkerList () {
    this.$axios.get(this.$apis.recruitUse.selectYhWorkerInfoByPage, {
      projectId: this.info.projectId
    }).then(res => {
      this.workerList = res.list.map((item: { workerId: string; workerName: string }) => {
        return {
          workerId: item.workerId,
          workerName: item.workerName,
          checked: false
        }
      })
      // 编辑置为已选择/编辑全选判断
      if (this.recordId) {
        this.tableData.forEach((item, index) => {
          const workCheckList = this.getExtraWorkerList(index)
          // 编辑置为已选择
          item.workerIdList.forEach(items => {
            this.workerList.forEach(workItem => {
              if (items === workItem.workerId) {
                workItem.checked = true
              }
            })
          })
          // 编辑全选判断
          if (workCheckList.length === item.workerIdList.length) {
            item.allChecked = true
          }
        })
      }
    })
  }

  // 查询项目在岗班次列表
  getWorkTimeListByProjectId () {
    this.$axios.get(this.$apis.recruitUse.selectYhWorkerRulesByProjectId, {
      projectId: this.info.projectId
    }).then(res => {
      this.workTimeList = res.timeList || []
    })
  }

  // 去除重复的姓名
  getExtraWorkerList (rowIndex: number) {
    const list: any[] = []
    this.tableData.forEach((item, index) => {
      if (rowIndex !== index) {
        item.workerIdList.forEach((workerId: string) => {
          list.push({
            workerId: workerId
          })
        })
      }
    })
    return this.workerList.filter((item) => {
      return list.every((item1) => {
        return item.workerId !== item1.workerId
      })
    })
  }

  changeAllChecked (rowIndex: number) {
    const workCheckList = this.getExtraWorkerList(rowIndex)
    this.tableData[rowIndex].workerIdList = []
    if (this.tableData[rowIndex].allChecked) {
      workCheckList.forEach(item => {
        item.checked = true
        this.tableData[rowIndex].workerIdList.push(item.workerId)
      })
    } else {
      workCheckList.forEach(item => {
        item.checked = false
      })
    }
  }

  // 选择人员
  changeChecked (rowIndex: number, item: { workerId: string; workerName: string; checked: boolean }, row: PersonList) {
    if (item.checked) {
      this.tableData[rowIndex].workerIdList.push(item.workerId)
    } else {
      this.tableData[rowIndex].workerIdList.forEach((items, index) => {
        if (items === item.workerId) {
          this.tableData[rowIndex].workerIdList.splice(index, 1)
        }
      })
    }
    // 判断是否全选
    let type = true
    const workCheckList = this.getExtraWorkerList(rowIndex)
    workCheckList.forEach(item => {
      if (!item.checked) {
        type = false
      }
    })
    row.allChecked = type
  }

  // 排班人员新增
  addPerson () {
    if (!this.info.projectId) {
      this.$message.warning('先选择项目')
    } else if (this.operation) {
      this.$message.warning('请先保存数据')
    } else {
      this.operation = true
      this.tableData.push({
        workerIdList: [],
        timeIdList: [],
        isEdit: true
      })
      this.$nextTick(() => {
        ;(this.$refs.table as any).bodyWrapper.scrollTop = (this.$refs
          .table as any).bodyWrapper.scrollHeight
      }) // 滚动到表格最底部
    }
  }

  getWorkTable (list: Array<string>) {
    const workers = this.workerList.filter((item) => {
      return list.indexOf(item.workerId) !== -1
    })
    let data = ''
    workers.forEach(item => {
      data += item.workerName + ', '
    })
    return {
      data: data,
      length: workers.length
    }
  }

  getWorkTimeTable (list: Array<string>) {
    const workers = this.workTimeList.filter((item: TimeList) => {
      return list.find((items: string) => items === item.timeId)
    })
    let data = ''
    workers.forEach(item => {
      data += item.startTime + '~' + item.endTime + ' '
    })
    return data
  }

  onSave (row: PersonList, index: number) {
    ;(this.$refs.row as ElForm).validate(valid => {
      ;(this.$refs.timeIdList as ElForm).validate(valid1 => {
        if (valid && valid1) {
          this.operation = false
          delete this.tableData[index].isEdit
          this.$set(this.tableData, index, row)
          this.countSchedulingSum()
        }
      })
    })
  }

  onEdit (row: PersonList, index: number) {
    if (this.operation) {
      this.$message.warning('请先保存数据')
    } else {
      this.operation = true
      // this.tableData[index].isEdit = true
      this.$set(this.tableData[index], 'isEdit', true)
      // 判断是否全选
      let type = true
      const workCheckList = this.getExtraWorkerList(index)
      workCheckList.forEach(item => {
        if (!item.checked) {
          type = false
        }
      })
      row.allChecked = type
    }
  }

  onDelete (index: number) {
    // 删除复选框置为未选择
    this.workerList.forEach(item => {
      this.tableData[index].workerIdList.forEach(items => {
        if (item.workerId === items) {
          item.checked = false
        }
      })
    })
    this.tableData.splice(index, 1)
    this.operation = false
    this.countSchedulingSum()
  }

  // 计算排班人数
  countSchedulingSum () {
    let numbers = 0
    this.tableData.forEach(item => {
      numbers += item.workerIdList.length
    })
    this.info.schedulingSum = numbers.toString()
  }

  submit (callback: Function) {
    ;(this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        if (this.operation) {
          this.$message.warning('请先保存')
          return false
        } else if (!(this.tableData && this.tableData.length)) {
          this.$message.warning('请至少添加一个排班人员')
          return false
        }
        let url = this.$apis.recruitUse.insertYhWorkerRecord
        let data = {}
        if (this.recordId) {
          url = this.$apis.recruitUse.updateYhWorkerRecord
          data = {
            recordId: this.info.recordId
          }
        }
        const schedulingDate = (this.info.schedulingDate as Array<string>).join(',')
        this.$axios.post(url, {
          ...data,
          projectId: this.info.projectId,
          schedulingDate: schedulingDate,
          schedulingSum: this.info.schedulingSum,
          schedulingList: this.tableData
        }).then(() => {
          this.$message.success('保存成功')
          callback()
        })
      }
    })
  }

  onSubmit () {
    this.submit(() => {
      this.$router.back()
    })
  }

  onSubmit1 () {
    this.submit(() => {
      (this.$refs.info as ElForm).resetFields()
      this.tableData = []
      this.disabled = true
    })
  }
}
